export function fillRememberedUser(form) {
    // check if user already exists in local storage
    const savedUser = window.localStorage.getItem("UserInfo");

    if (!savedUser) return;

    // parse saved user from JSON
    const parsedUser = JSON.parse(savedUser);
    
    parsedUser.forEach(element => {
        for (const [key, value] of Object.entries(element)) {
            const formField = form.querySelector(`[name=${key}]`);

            // Populate field if user exists
            if(formField) {
                if (formField.type === "checkbox") {
                    formField.checked = value === true;
                } else {
                    formField.value = value;
                }
            }
        }
    });
}