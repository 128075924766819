import { fillRememberedUser } from "./fillRememberedUser.mjs";
import { submitNewsletterForm } from "./submitNewsletterForm.mjs";

export function showNewsletterForm() {
  const newsletterButtons = document.querySelectorAll(
    "[data-template='newsletter']",
  );

  if (newsletterButtons.length > 0) {
    for (const nlButton of newsletterButtons) {
      // clone template with participation form
      nlButton.addEventListener("click", (event) => {
        const documentBody = document.querySelector("html");
        documentBody.classList.add("overflow-hidden");
        document
          .querySelector(".ewe-alarm-popup")
          .classList.remove("show", "fade");

          const templateContent = document.getElementById(event.target.dataset.template).content.cloneNode(true);

          document.body.appendChild(templateContent)
          
          const template = document.querySelector(".ewe-participation-form-overlay");

        // fade in overlay
        // const overlay = template.querySelector(
        //   ".ewe-participation-form-overlay",
        // );
        const form = template.querySelector("[data-form='newsletter']");

        const overlayTimeout = setTimeout(() => {
          template.classList.add("fade");
          clearTimeout(overlayTimeout);
        }, 100);

        // attach event listener for closing overlay
        const closeButtons = template.querySelectorAll("[data-close-overlay]");
        closeButtons.forEach((close) => {
          close.addEventListener("click", () => {
            closeOverlay();
          });
        });

        function closeOverlay() {
          template.classList.remove("fade");

          const timeout = setTimeout(() => {
            documentBody.classList.remove("overflow-hidden");
            // calendar.removeChild(overlay);
            template.classList.add("template");
            if (template.querySelector(".ewe-form-errors")) {
              template
                .querySelector(".ewe-form-errors")
                .classList.remove("show");
            }
            if (template.querySelector(".ewe-form-success")) {
              template
                .querySelector(".ewe-form-success")
                .classList.remove("show");
            }
            form.classList.remove("hide");

            template.remove();
            clearTimeout(timeout);
          }, 310);
        }

        // attach submit event listener to the form
        submitNewsletterForm(form);

        // fill the form if user is saved
        fillRememberedUser(form);
      });
    }
  }
}
