// Show participation form
import { showParticipationForm } from "./modules/showParticipationForm.mjs";

// Show newsletter form
import { showNewsletterForm } from "./modules/showNewsletterForm.mjs";

// Show alarm clock popup
import { alarmPopup } from "./modules/alarmPopup.mjs";

// Show show
import { snow } from "./modules/snow.mjs";

// Accordion
import { toggleAccordion } from "./modules/accordion.mjs";


showParticipationForm();
alarmPopup();
showNewsletterForm();
snow();
toggleAccordion();