import { submitParticipationForm } from "./submitParticipationForm.mjs";
import { fillRememberedUser } from "./fillRememberedUser.mjs";
export function showParticipationForm() {
    const days = document.querySelectorAll("button.ewe-advent-calendar__grid-item");

    if (days.length > 0) {
        window.dataLayer = window.dataLayer || [];

        days.forEach(day => {
            day.addEventListener("click", () => {
                const documentBody = document.querySelector("html");
                documentBody.classList.add("overflow-hidden");

                // Get prize name and image
                const prizeName = day.querySelector("[data-prize-name]").textContent;
                const prizeImage = day.dataset.prizeImage;
                const prizeDay = day.querySelector("[data-day]").textContent;
                const prizeDescription = day.querySelector("[data-prize-copy]").content.cloneNode(true);
                const prizeProductImage = day.dataset.productImage;
                const prizeProductBgMobile = day.dataset.productBgMobile;
                const prizeProductBgDesktop = day.dataset.productBgDesktop;
                const prizeProductTitle = day.querySelector("[data-product-title]").content.cloneNode(true);
                const prizeProductSubline = day.querySelector("[data-product-subline]").content.cloneNode(true);
                const prizeProductCopy = day.querySelector("[data-product-copy]").content.cloneNode(true);
                const prizeProductLink = day.querySelector("[data-product-link]").textContent;
                const prizeCurrentDay = day.querySelector("[data-current-day]").textContent;
                const prizeWinnersAnnounced = day.dataset.announceWinners;
                const winnersText = day.querySelector("[data-winners]").content.cloneNode(true);
                const status = day.dataset.status;

                // track cliked day
                window.dataLayer.push({
                    'event': 'gaEvent',
                    'eventCategory': 'Adventskalender',
                    'eventAction': `Adventskalender-Tür: ${prizeCurrentDay}`,
                    'eventLabel': 'Adventskalender-Tür geöffnet'
                });

                // show wrong window popup if upcoming window is clicked
                if (status === "upcoming") {
                    const wrongWindowPopup = document.getElementById("wrong-window");
                    wrongWindowPopup.classList.add("show");
                    const wrongWindowClose = wrongWindowPopup.querySelector("button");

                    const showTimeout = setTimeout(() => {
                        wrongWindowPopup.classList.add("fade");
                        clearTimeout(showTimeout);
                    }, 10);

                    wrongWindowClose.addEventListener("click", () => {
                        wrongWindowPopup.classList.remove("fade");

                        const removeTimeout = setTimeout(() => {
                            documentBody.classList.remove("overflow-hidden");
                            wrongWindowPopup.classList.remove("show");
                            clearTimeout(removeTimeout);
                        }, 310);
                    });

                    return;
                }
                
                const templateContent = document.getElementById(day.dataset.template).content.cloneNode(true);

                document.body.appendChild(templateContent)
                
                const template = document.querySelector(".ewe-participation-form-overlay");
                // template.classList.remove("template");

                const form = template.querySelector(".ewe-advent-calendar-form");
                const passedDayEl = template.querySelector(".passed-day");

                // Don't show form if day is already passed
                if (status === "passed") {
                    form.style.display = "none";
                    passedDayEl.style.display = "block";
                    if (prizeWinnersAnnounced === "true") {
                        passedDayEl.classList.add("show-winners");
                        passedDayEl.querySelector(".winners-announced p").appendChild(winnersText);
                    } else {
                        passedDayEl.classList.remove("show-winners");
                    }
                } else {
                    form.style.display = "grid";
                    passedDayEl.style.display = "none";
                    passedDayEl.classList.remove("show-winners");
                }

                // fade in overlay
                // const overlay = template.querySelector(".ewe-participation-form-overlay");

                const overlayTimeout = setTimeout(() => {
                    template.classList.add("fade");
                    clearTimeout(overlayTimeout);
                }, 100);

                // attach event listener for closing overlay
                const closeButtons = template.querySelectorAll("[data-close-overlay]");
                closeButtons.forEach((close) => {
                    close.addEventListener("click", () => {
                        closeOverlay()
                    });
                });

                // Add prize day
                const prizeDayEl = template.querySelector(".prize-day");
                prizeDayEl.textContent = prizeDay;

                // Add prize image
                const prizeImageEl = template.querySelector(".prize-image");
                prizeImageEl.setAttribute("src", prizeImage);
                prizeImageEl.setAttribute("alt", prizeName);
                prizeImageEl.dataset.day = prizeCurrentDay;

                // Add prize name
                template.querySelector("h2").textContent = prizeName;

                // Add prize description
                const prizeDescriptionEl = template.querySelector(".prize-description");
                prizeDescriptionEl.appendChild(prizeDescription);

                // Add product image
                const productImageEl = template.querySelector(".ewe-advent-product__image");
                productImageEl.setAttribute("src", prizeProductImage);
                productImageEl.setAttribute("alt", "image");
                productImageEl.dataset.day = prizeCurrentDay;

                // Add product background
                const productBgEl = template.querySelector(".ewe-advent-product__bg");
                productBgEl.querySelector("img").setAttribute("src", prizeProductBgMobile);
                productBgEl.querySelector("source").setAttribute("srcset", prizeProductBgDesktop);

                // Add product title
                const productTitleEl = template.querySelector(".ewe-advent-product h2");
                productTitleEl.appendChild(prizeProductTitle);

                // Add product subline
                const productSublineEl = template.querySelector(".ewe-advent-product_headling_h4");
                productSublineEl.appendChild(prizeProductSubline);

                // Add product copy
                const productCopyEl = template.querySelector(".ewe-advent-product-description__content");
                // productCopyEl.innerHTML = prizeProductCopy;
                productCopyEl.appendChild(prizeProductCopy);

                // Add product link
                const productLinkEl = template.querySelector(".ewe-advent-product a");
                productLinkEl.setAttribute("href", prizeProductLink);

                // close overlay
                function closeOverlay() {
                    template.classList.remove("fade");

                    const timeout = setTimeout(() => {
                        documentBody.classList.remove("overflow-hidden");
                        // calendar.removeChild(overlay);
                        template.classList.add("template");
                        if (template.querySelector(".ewe-form-errors")) {
                            template.querySelector(".ewe-form-errors").classList.remove("show");
                        }
                        if (template.querySelector(".ewe-form-success")) {
                            template.querySelector(".ewe-form-success").classList.remove("show");
                        }

                        // remove image sources
                        prizeImageEl.setAttribute("src", "");
                        productImageEl.setAttribute("src", "");
                        productBgEl.querySelector("img").setAttribute("src", "");
                        productBgEl.querySelector("source").setAttribute("srcset", "");

                        // close product description if opened
                        const accordionTrigger = template.querySelector("[data-toggle-accordion]");
                        const accordionBody = template.querySelector(".ewe-advent-product-description__body");
                        accordionTrigger.setAttribute("aria-expanded", "false");
                        accordionBody.setAttribute("aria-hidden", "true");
                        
                        // remove product description
                        productCopyEl.innerHTML = "";
                        productSublineEl.innerHTML = "";
                        prizeDescriptionEl.innerHTML = "";
                        passedDayEl.querySelector(".winners-announced p").innerHTML = "";
                        productTitleEl.innerHTML = "";

                        template.remove()
                        
                        clearTimeout(timeout);

                    }, 310);
                }

                // attach submit event listener to the form
                submitParticipationForm(form);

                // fill the form if user is saved
                fillRememberedUser(form);
            });
        });
    }
}
