export function alarmPopup() {
    const alarmPopupEl = document.querySelector(".ewe-alarm-popup");
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    document.documentElement.style.setProperty('--scrollbar-width', `${scrollbarWidth}px`);

    if (alarmPopupEl) {
        const openPopupButton = alarmPopupEl.querySelector("[data-open-alarm]");

        openPopupButton.addEventListener("click", showAlarmPopup);

        function showAlarmPopup() {
            alarmPopupEl.classList.add("show");

            const timeout2 = setTimeout(() => {
                alarmPopupEl.classList.add("fade");
                clearTimeout(timeout2)
            }, 300);
        }

        // show alarm popup when page is loaded
        document.addEventListener("DOMContentLoaded", () => {
            const timeout = setTimeout(() => {
                showAlarmPopup();
                clearTimeout(timeout);
            }, 2000);
        });

        // close popup
        const closeButton = alarmPopupEl.querySelector("[data-close-alarm]");

        closeButton.addEventListener("click", () => {
            alarmPopupEl.classList.remove("fade");

            const timeout = setTimeout(() => {
                alarmPopupEl.classList.remove("show");
                // alarmPopupEl.remove();
                clearTimeout(timeout);
            }, 310);
        });
    }
}
