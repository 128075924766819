export function submitNewsletterForm(form) {
    let pendingFormSubmitRequest = false;

    form.addEventListener('submit', async (e) => {
        e.preventDefault();

        if (pendingFormSubmitRequest) return;

        // Form validation
        let formValid = true;

        const requiredInputs = form.querySelectorAll("[required]");
        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,17}$/;

        requiredInputs.forEach(input => {
            // remove error on focus
            input.addEventListener("focus", () => {
                input.closest("div").classList.remove('invalid');

                if (input.name === "salutation") {
                    input.closest("div:not(.select-container)").classList.remove('invalid');
                }
            });

            // Check if text field is valid
            if (input.type === "text" && input.value.trim() === "") {
                input.closest("div").classList.add('invalid');
                formValid = false;
            }

            // Check if email address is valid
            if ((input.type === "email" && input.value.trim() === "") || (input.type === "email" && !emailRegex.test(input.value))) {
                input.closest("div").classList.add('invalid');
                formValid = false;
            }

            // Check if use terms are checked
            if (input.type === "checkbox" && !input.checked) {
                input.closest("div").classList.add('invalid');
                formValid = false;
            }

            if (input.name === "salutation" && input.value.trim() === "") {
                input.closest("div:not(.select-container)").classList.add('invalid');
                formValid = false;
            }
        });

        const zipCodeInput = form.querySelector("[name='zipcode']");
        const zipCodeRegex = /^\d{5}$/;

        zipCodeInput.addEventListener("focus", () => {
            zipCodeInput.closest("div").classList.remove('invalid');
        });

        if (zipCodeInput.value !== "" && !zipCodeRegex.test(zipCodeInput.value)) {
            zipCodeInput.closest("div").classList.add("invalid");
            formValid = false;
        }

        if (formValid) {
            const submitButton = form.querySelector("[data-submit-newsletter]");
            submitButton.classList.add("loading");

            const errorContainer = form.querySelector(".ewe-form-errors");
            errorContainer.classList.remove("show");

            pendingFormSubmitRequest = true;

            const formUrl = form.getAttribute("data-url");
            const formData = new FormData(form);
            const userData = Object.fromEntries(formData.entries());
            userData.terms = form.querySelector("[name='terms']").checked;

             // transfrom email to lowercase
             userData.email = userData.email.toLowerCase(); 

            try {
                const response = await fetch(formUrl, {
                    method: "POST",
                    headers: {
                        "Content-type": "application/json"
                    },
                    body: JSON.stringify(userData)
                });

                const result = await response.json();

                if (!response.ok) {
                    throw new Error(result.message || "Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.");
                }

                if (result.success) {
                    const successContainer = form.closest(".ewe-participation-form-overlay").querySelector(".ewe-form-success");
                    successContainer.classList.add("show");
                    form.reset();
                    form.classList.add("hide");

                    // scroll success message into view
                    successContainer.scrollIntoView({ behavior: "smooth" });
                }

            } catch (error) {
                errorContainer.querySelector("p").innerHTML = error.message;
                errorContainer.classList.add("show");
                errorContainer.scrollIntoView({ behavior: "smooth" });
            } finally {
                pendingFormSubmitRequest = false;
                submitButton.classList.remove("loading");
            }
        } else {
            // scroll first error message into view
            form.querySelector(".invalid").scrollIntoView({ behavior: "smooth" });
        }
    });
}
