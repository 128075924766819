export function toggleAccordion() {
    const accordionToggle = document.querySelectorAll("[data-toggle-accordion]");

    if (accordionToggle.length > 0) {

        accordionToggle.forEach(accordion => {
            accordion.addEventListener("click", () => {
                const accordionElement = accordion.closest(".ewe-advent-product-description");
                const onlyOneActive = accordionElement.dataset.onlyOneActive;
                const isExpanded = accordion.getAttribute("aria-expanded");
                const accordionBody = accordion.parentElement.nextElementSibling;

                if (onlyOneActive === "true") {
                    toggleAllAccordionItems(accordionElement, accordion, accordionBody, isExpanded);
                } else {
                    toggleSingleAccordionItem(accordion, accordionBody, isExpanded);
                }
            });
        });

        function toggleSingleAccordionItem(trigger, accBody, isExpanded) {
            if (isExpanded === "true") {
                trigger.setAttribute("aria-expanded", "false");
                accBody.setAttribute("aria-hidden", "true");

            } else {
                trigger.setAttribute("aria-expanded", "true");
                accBody.setAttribute("aria-hidden", "false");
            }
        }

        function toggleAllAccordionItems(accordionElement, trigger, accBody, isExpanded) {
            const accordionItems = accordionElement.querySelectorAll(".ewe-advent-product-description__item");

            // collapse all accordion items
            accordionItems.forEach(item => {
                const button = item.querySelector("[data-toggle-accordion]");
                const body = item.querySelector("[aria-hidden]");

                button.setAttribute("aria-expanded", "false");
                body.setAttribute("aria-hidden", "true");
            });

            // open clicked accordion item
            toggleSingleAccordionItem(trigger, accBody, isExpanded);
        }
    }
}
